import React, { useEffect } from "react";
import { PrimaryButtonLarge, PrimaryButtonSmall } from "../utils/buttons";
import Navbar from "../utils/navbar";
import { H1, H2, H3 } from "../utils/typography";
import FeatureTabs from "./feature-tabs";
import ImageSwapper from "./img-swapper";
import { Testimonials } from "./testimonials";

function Home(props) {
  const features = [
    {
      img: "authentication.svg",
      heading: "Authenticated users",
      description:
        "Socially authenticated posts. So no SPAM. Spend time on meaningful questions from high-intent visitors.",
    },
    {
      img: "data-security.svg",
      heading: "Respect visitors privacy",
      description:
        "Visitors can post anonymously, encouraging honest QnA and protecting their data.",
    },
    {
      img: "discussions.svg",
      heading: "After they’ve left",
      description:
        "Reach visitors, notify them of your responses, even after they have left your website. Even for anonymous posts!",
    },
    {
      img: "faq-base.svg",
      heading: "Seed your questions",
      description:
        "Don't wait till they ask. Publish your own FAQ, to kindle the conversation, and guide customers.",
    },
    {
      img: "seo.svg",
      heading: "Automatic SEO",
      description:
        "hagrid automatically feeds your QnA to the Google FAQ Schema, rank higher with zero effort.",
    },
    {
      img: "suggestions.svg",
      heading: "Hyperintelligent Autocomplete",
      description:
        "Get them to the answers, while they are asking the questions",
    },
    {
      img: "rocket.svg",
      heading: "Powerful and flexible",
      description:
        "Order questions as you like, respond in private, restrict answers to particular pages and anything else you can think of",
    },
    {
      img: "globe.svg",
      heading: "Speak any language",
      description:
        "We support all the major languages of the world. If we don't speak yours, we'll add it in 15 minutes.",
    },

    {
      img: "controls.svg",
      heading: "Infinite customisation",
      description:
        "Get hagrid to match your webpage brand and theme, down to the last pixel; without writing any code.",
    },
  ];

  const integrations = [
    {
      img: "js-mono.svg",
      name: "Javascript",
      url: "https://aarzoo.notion.site/Add-the-hagrid-QnA-widget-to-any-page-with-Javascript-1c2fbf3e1add442f86b1f17794a68402",
    },
    {
      img: "webflow-mono.svg",
      name: "Webflow",
      url: "https://aarzoo.notion.site/Add-the-hagrid-QnA-widget-to-your-Webflow-site-36a4873b13764041a599c29a0c4e5854",
    },
    {
      img: "react-mono.svg",
      name: "ReactJS",
      url: "https://aarzoo.notion.site/Add-the-hagrid-QnA-widget-to-your-React-page-436bc3bca36c47e6aed28e2c4d0d416c",
    },
    {
      img: "wp-mono.svg",
      name: "Wordpress",
      url: "https://aarzoo.notion.site/Add-the-hagrid-QnA-widget-to-your-Wordpress-Page-fd6651d1b90f45728fd60048e5d5a158",
    },
    {
      img: "wix-mono.svg",
      name: "Wix",
      url: "https://aarzoo.notion.site/Add-the-hagrid-QnA-widget-to-your-Wix-Site-3e1d7dfe9dde4de79c380153d8051826",
    },
    {
      img: "shopify-mono.svg",
      name: "Shopify",
      url: "https://aarzoo.notion.site/Add-the-hagrid-QnA-widget-to-your-Shopify-Store-9c2492ec23044e7192de6ba5d5889781",
    },
    {
      img: "carrd-mono.svg",
      name: "Carrd",
      url: "https://aarzoo.notion.site/Add-the-hagrid-QnA-widget-to-your-Carrd-Page-aaf5b4aa0f794f6285a86fd30d45fe50",
    },
  ];

  return (
    <div className="min-h-screen antialiased">
      <Navbar />
      {/* <div className="bg-gradient-to-tr from-emerald-50 to-fuchsia-100 w-full h-full"> */}
      <div className="w-full h-full">
        {/* <div className="bg-[#fbe8de] bg-texture pt-8 flex items-center justify-center">
          <a
            href="https://www.producthunt.com/posts/hagrid-2-0?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-hagrid&#0045;2&#0045;0"
            target="_blank"
            className="flex flex-col items-center"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=351467&theme=light"
              alt="hagrid&#0032;2&#0046;0 - Social&#0032;QnA&#0044;&#0032;you&#0032;can&#0032;add&#0032;to&#0032;any&#0032;webpage&#0032;in&#0032;10&#0032;mins&#0033;&#0032; | Product Hunt"
              // style={{ width: "250px", height: "54px" }}
              // width="250"
              // height="54"
              className="w-48 sm:w-60"
            />
          </a>
        </div> */}

        {/* Hero section */}
        <main className="flex flex-col items-center pt-32 pb-[4.875rem] w-full px-6 bg-[#FDEFEF] bg-texture relative overflow-hidden">
          {/* <main className="flex flex-col items-center pt-16 pb-[4.875rem] w-full px-6 bg-[#fbe8de] bg-texture relative overflow-hidden"> */}
          <img
            src="assets/images/scribble-3.svg"
            className="w-24 sm:w-36 lg:w-60 absolute -left-12 -top-0 sm:-left-8 sm:top-28"
            loading="lazy"
          />
          <img
            src="assets/images/scribble-2.svg"
            className="w-36 sm:w-44 lg:w-60 absolute -right-16 sm:-right-12 top-28"
            loading="lazy"
          />
          <div className="max-w-2xl text-center">
            <H1 text="Social Q&A for any website" />
          </div>
          <div className="mt-6 mb-12 max-w-2xl text-center">
            <h3 className="font-brandon font-light text-2xl sm:text-3xl text-zinc-800">
              No-code widget, to get visitors talking
            </h3>
          </div>
          <PrimaryButtonLarge
            text={"Try for Free"}
            onClick={() =>
              window.open("https://dashboard.hagrid.io/login", "_self")
            }
          />
          <p className="font-brandon text-base font-normal mt-4 mb-8 text-zinc-700 text-center">
            14 day trial. No credit card needed.
          </p>
          <div className="hidden lg:grid grid-cols-3 gap-12">
            {/* <div className="shadow-[12px_12px_0_0_rgba(255,115,150,0.4)] rounded-2xl scale-95"> */}
            <div className="shadow-2xl rounded-2xl scale-95">
              <img src="assets/images/hero-img-1.svg" loading="lazy" />
            </div>
            {/* <div className="shadow-[12px_12px_0_0_rgba(20,195,142,0.4)] rounded-2xl scale-95"> */}
            <div className="shadow-2xl rounded-2xl scale-95">
              <img src="assets/images/hero-img-2.svg" loading="lazy" />
            </div>
            {/* <div className="shadow-[12px_12px_0_0_rgba(209,140,224,0.4)] rounded-2xl scale-95"> */}
            <div className="shadow-2xl rounded-2xl scale-95">
              <img src="assets/images/hero-img-3.svg" loading="lazy" />
            </div>
          </div>
          {/* <p className="font-brandon text-xl font-normal mt-12 text-zinc-700 text-center hidden lg:block px-6">
            hagrid can be used for any website, from{" "}
            <span className="bg-[rgba(255,115,150,0.4)] rounded-full px-3 py-1.5 text-zinc-900 font-bold cursor-pointer">
              e-commerce
            </span>{" "}
            to{" "}
            <span className="bg-[rgba(20,195,142,0.4)] rounded-full px-3 py-1.5 text-zinc-900 font-bold cursor-pointer">
              SAAS
            </span>{" "}
            pages to{" "}
            <span className="bg-[rgba(209,140,224,0.4)] rounded-full px-3 py-1.5 text-zinc-900 font-bold cursor-pointer">
              blogs
            </span>{" "}
            . Try it out.
          </p> */}
          <ImageSwapper />
        </main>

        {/* Features at a glance */}
        <div className="bg-texture py-32 px-4 md:px-24 relative overflow-hidden">
          <img
            src="assets/images/scribble-9.svg"
            className="w-20 sm:w-32 lg:w-40 absolute -left-6 sm:-left-16 top-1/2"
            loading="lazy"
          />
          <img
            src="assets/images/scribble-8.svg"
            className="w-20 sm:w-28 lg:w-32 absolute -right-12 top-0"
            loading="lazy"
          />
          <div className="text-center px-6">
            <H2 text="All of the results - none of the work!" />
          </div>
          <div className="mt-6 mb-12 px-6 text-center">
            <H3 text="In 15 minutes get set for automatic SEO, social proof and meaningful engagement" />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-16 sm:gap-24">
            {features.map((feature, index) => (
              <div className="flex flex-col items-center" key={index}>
                <img src={`assets/images/${feature.img}`} loading="lazy" />
                <p className="font-bevietnampro text-xl sm:text-2xl font-bold mt-8 text-zinc-900 text-center">
                  {feature.heading}
                </p>
                <p className="font-normal font-brandon text-base sm:text-lg mt-2 text-zinc-700 text-center">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Integrations  */}
        <div
          id="integrations"
          className="bg-zinc-900 py-10 flex flex-col items-center relative  px-2 md:px-24"
        >
          <div className="text-xl sm:text-2xl font-bevietnampro font-bold text-white mb-6 text-center relative w-fit">
            On your website in less than 10 minutes
            <img
              src="assets/images/wavy-line.svg"
              className="absolute hidden sm:block sm:-bottom-1 left-0 sm:-left-20 w-16"
            />
            <img
              src="assets/images/wavy-line.svg"
              className="absolute -bottom-7 sm:-bottom-1 right-0 sm:-right-20 w-16"
            />
          </div>

          <div className="flex flex-wrap gap-6 justify-center w-full">
            {integrations.map((integration, index) => (
              <button
                key={index}
                className="shadow-[8px_8px_0_0_rgba(255,255,255,0.3)] h-16 w-16 flex flex-shrink-0 items-center justify-center bg-white rounded-lg border hover:-translate-y-1.5
                            "
                onClick={() => window.open(integration.url, "_blank")}
              >
                <img
                  src={`assets/images/${integration.img}`}
                  loading="lazy"
                  className="scale-75"
                />
              </button>
            ))}
          </div>
        </div>

        {/* Dashboard features */}
        <div className="py-32 md:px-24 bg-texture bg-[#EDFFEC] bg-opacity-50 relative overflow-hidden">
          <img
            src="assets/images/scribble-10.svg"
            className="w-20 sm:w-24 lg:w-32 absolute -left-8 top-0"
            loading="lazy"
          />
          <div className="text-center px-6">
            <H2 text="Powerful and Simple. Gets things done!" />
          </div>
          <div className="mt-6 mb-12 px-6 text-center">
            <H3 text="The hagrid dashboard puts everything you need to manage your QnA, at your fingertips." />
          </div>
          <FeatureTabs />
        </div>

        {/* hagrid */}
        <div className="py-32 px-6 md:px-24 bg-texture bg-[#FFDEFA] bg-opacity-40 relative overflow-hidden">
          <img
            src="assets/images/scribble-5.svg"
            className="w-20 sm:w-32 lg:w-40 absolute -left-8 sm:-left-16 top-1/2"
            loading="lazy"
          />
          <img
            src="assets/images/scribble-4.svg"
            className="w-20 sm:w-32 lg:w-40 absolute top-0 -right-8 sm:-right-12 lg:top-12"
            loading="lazy"
          />
          <div className="text-center px-6">
            <H2 text="See hagrid in action" />
          </div>
          <div className="mt-6 mb-12 px-6 text-center">
            <H3 text="Ask us anything you'd like, we're using hagrid to put this FAQ up" />
          </div>
          <div className="hagrid"></div>
        </div>

        {/* Testimonials */}
        <div className="py-32 px-6 md:px-24 bg-texture bg-[#8384DF] bg-opacity-20 relative overflow-hidden">
          <img
            src="assets/images/scribble-6.svg"
            className="w-20 sm:w-32 lg:w-40 absolute -left-4 sm:-left-8 bottom-0"
            loading="lazy"
          />
          <img
            src="assets/images/scribble-7.svg"
            className="w-24 sm:w-40 lg:w-52 absolute top-0 -right-4 sm:-right-8 lg:top-20"
            loading="lazy"
          />
          <div className="text-center px-6">
            <H2 text="What they are saying about hagrid..." />
          </div>
          <div className="mt-6 mb-12 px-6 text-center">
            {/* <H3 text="People ❤️ us" /> */}
            <h3 className="font-brandon font-light text-xl sm:text-2xl text-zinc-800 antialiased flex items-center justify-center">
              People
              <img
                src="assets/images/heart.svg"
                className="w-6 sm:w-7 mx-1.5"
              />
              us
            </h3>
          </div>
          <Testimonials />
        </div>

        {/* Pricing */}
        <div
          id="pricing"
          className="py-32 px-6 md:px-24 bg-texture bg-[#FACDCD] bg-opacity-60 flex flex-col items-center relative overflow-hidden"
        >
          <img
            src="assets/images/scribble-stars.svg"
            className="absolute top-8 w-20 sm:w-24"
          />
          <div className="text-center px-6">
            <H2 text="Unlimited everything always" />
          </div>
          <div className="mt-6 mb-12 px-6 text-center">
            <H3 text="One simple plan. No limits. Pay annually and save." />
          </div>

          <div className="flex flex-col sm:flex-row justify-center items-center w-full antialiased">
            <div className="bg-white roudned-lg p-8 max-w-lg rounded-lg w-full sm:w-1/2 sm:mr-1 mb-1 sm:mb-0">
              <p className="font-bevietnampro text-xl text-zinc-900 font-bold">
                Monthly
              </p>

              <p className="font-bevietnampro font-bold text-5xl mt-8 text-zinc-900">
                $9.99 <span className="text-sm font-semibold">/ month</span>
              </p>
              <p className="font-plusjakartasans text-sm font-medium mb-12 text-zinc-700">
                Billed monthly
              </p>
              <PrimaryButtonSmall
                text="Try for Free"
                onClick={() =>
                  window.open("https://dashboard.hagrid.io/login", "_self")
                }
              />
            </div>
            <div className="bg-white roudned-lg p-8 max-w-lg rounded-lg w-full sm:w-1/2 sm:ml-1 mt-1 sm:mt-0 relative">
              <div className="absolute top-0 sm:-top-4 -left-4 sm:left-4 bg-[#eb2f06] -rotate-[16deg] rounded-lg px-2.5 py-1.5 font-plusjakartasans font-black text-xs text-white">
                MOST POPULAR
              </div>
              <img
                src="assets/images/scribble-1.svg"
                className="w-8 absolute -top-6 sm:-top-[2.75rem] left-28 sm:left-[8.75rem] rotate-2"
              />
              <p className="font-bevietnampro text-xl text-zinc-900 font-bold">
                Yearly
              </p>

              <p className="font-bevietnampro font-bold text-5xl mt-8 text-zinc-900">
                $7.99 <span className="text-sm font-semibold">/ month</span>
              </p>
              <p className="font-plusjakartasans text-sm font-medium mb-12 text-zinc-700">
                Billed at $95.88 /year
              </p>
              <PrimaryButtonSmall
                text="Try for Free"
                onClick={() =>
                  window.open("https://dashboard.hagrid.io/login", "_self")
                }
              />
            </div>
          </div>
        </div>

        {/* Happy to help */}
        <div className="bg-zinc-900 py-32 px-6 md:px-24 flex flex-col lg:flex-row">
          <div className="w-full lg:w-2/5 flex justify-center items-center mb-16 lg:mb-0">
            <div className="rounded-full w-80 flex justify-center relative">
              <img
                src="assets/images/vinod.jpg"
                className="lg:w-80 w-40 rounded-full"
                loading="lazy"
              />
              <img
                src="assets/images/mark-vinod.svg"
                loading="lazy"
                className="absolute top-32 sm:top-20 lg:top-72 -right-6 sm:-right-20 lg:-right-16"
              />
            </div>
          </div>

          <div className="flex flex-col items-center lg:items-start w-full lg:w-3/5">
            <div className="max-w-3xl relative">
              <p className="font-brandon font-bold text-xl sm:text-2xl lg:text-4xl text-white antialiased">
                Use my experience in transforming websites for conversion, and
                lessons from across 100s of websites. In 15 minutes, I’ll
                evaluate your website and set you up to win.
              </p>
              <img
                src="assets/images/quotes.svg"
                className="absolute -top-14 lg:-top-12 left-0 lg:-left-14"
                loading="lazy"
              />
            </div>
            <button
              className="antialiased bg-white text-zinc-900 py-4 px-8 sm:py-6 sm:px-12 rounded-lg font-extrabold mt-12 font-bevietnampro text-lg hover:-translate-y-1.5 shadow-[8px_8px_0_0_rgba(255,255,255,0.3)]"
              onClick={() => {
                window.open(
                  "https://calendly.com/vinodbollini/chat-about-hagrid",
                  "_blank"
                );
              }}
            >
              Schedule a call with me
            </button>
          </div>
        </div>

        {/* Footer */}
        <div className="bg-white w-full px-4 md:px-24 py-12">
          <div className="md:hidden flex items-center justify-between mb-12 md:mb-0">
            <img
              src="/assets/images/hagrid-logo-alt.svg"
              alt="logo"
              loading="lazy"
              className="w-[7rem]"
            />

            <a
              href="https://www.producthunt.com/posts/hagrid?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-hagrid"
              target="_blank"
              className="flex flex-col items-end"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=329291&theme=neutral&period=daily"
                alt="hagrid - No&#0045;code&#0032;user&#0045;generated&#0032;FAQ&#0044;&#0032;for&#0032;any&#0032;webpage | Product Hunt"
                className="w-52"
              />
            </a>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-5 gap-x-4 gap-y-10">
            <div className="hidden md:block">
              <img
                src="/assets/images/hagrid-logo-alt.svg"
                alt="logo"
                loading="lazy"
                className="w-[7rem]"
              />

              <a
                href="https://www.producthunt.com/posts/hagrid?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-hagrid"
                target="_blank"
              >
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=329291&theme=neutral&period=daily"
                  alt="hagrid - No&#0045;code&#0032;user&#0045;generated&#0032;FAQ&#0044;&#0032;for&#0032;any&#0032;webpage | Product Hunt"
                  // style="width: 250px; height: 54px;"
                  // width="250"
                  // height="54"
                  className="mt-8 w-44"
                />
              </a>
            </div>

            <div>
              <p className="font-bold text-zinc-800 text-lg antialiased font-bevietnampro">
                Integration Guides
              </p>
              <div className="mt-3 text-zinc-800 antialiased font-brandon flex flex-col">
                {integrations.map((integration, index) => (
                  <a
                    key={index}
                    href={integration.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-zinc-800 font-normal py-2 hover:text-zinc-900 "
                  >
                    {integration.name}
                  </a>
                ))}
              </div>
            </div>

            <div>
              <p className="font-bold text-zinc-800 text-lg antialiased font-bevietnampro">
                About
              </p>
              <div className="mt-3 text-zinc-800 antialiased font-brandon flex flex-col">
                <a
                  href="https://trello.com/b/33qnUVJ4"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-zinc-800 font-normal py-2 hover:text-zinc-900 "
                >
                  Roadmap
                </a>
                <a
                  href="https://buttondown.email/aarzoo/archive/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-zinc-800 font-normal py-2 hover:text-zinc-900 "
                >
                  Story
                </a>
                <a
                  href="https://aarzoo.notion.site/The-Handbook-70cd395b4d6e4e50af33d1a64c45dd8b"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-zinc-800 font-normal py-2 hover:text-zinc-900 "
                >
                  Handbook
                </a>
                <a
                  href="mailto:help@hagrid.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-zinc-800 font-normal py-2 hover:text-zinc-900 "
                >
                  Contact Us
                </a>
                <a
                  href="https://twitter.com/aarzooHQ"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-zinc-800 font-normal py-2 hover:text-zinc-900 "
                >
                  Twitter
                </a>
                <a
                  href="https://www.linkedin.com/company/aarzoohq"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-zinc-800 font-normal py-2 hover:text-zinc-900 "
                >
                  LinkedIn
                </a>
              </div>
            </div>

            <div>
              <p className="font-bold text-zinc-800 text-lg antialiased font-bevietnampro">
                Projects
              </p>
              <div className="mt-3 text-zinc-800 antialiased font-brandon flex flex-col">
                <a
                  href="https://magiceraser.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-zinc-800 font-normal py-2 hover:text-zinc-900 "
                >
                  magiceraser.io
                </a>
                <a
                  href="https://www.backgrounderaser.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-zinc-800 font-normal py-2 hover:text-zinc-900 "
                >
                  backgrounderaser.io
                </a>
                <a
                  href="https://hagrid.io/"
                  target="_blank"
                  className="text-zinc-800 font-normal py-2 hover:text-zinc-900 "
                >
                  hagrid.io
                </a>
              </div>
            </div>

            <div>
              <p className="font-bold text-zinc-800 text-lg antialiased font-bevietnampro">
                Legal
              </p>
              <div className="mt-3 text-zinc-800 antialiased font-brandon flex flex-col">
                <a
                  href="https://aarzoo.co/legal/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-zinc-800 font-normal py-2 hover:text-zinc-900 "
                >
                  Privacy Policy
                </a>
                <a
                  href="https://aarzoo.co/legal/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-zinc-800 font-normal py-2 hover:text-zinc-900 "
                >
                  Terms of Service
                </a>
              </div>
            </div>
          </div>

          <p className="text-xs mt-12 text-zinc-800 text-center">
            Copyright © 2020-2022 aarzoo, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
