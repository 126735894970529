import React, { useState } from "react";

function FeatureTabs(props) {
  const tabs = [
    {
      key: 0,
      title: "Respond and Connect",
      description:
        "Answer questions privately or notify users (even after they have left), and post rich replies to questions",
      // image: "https://source.unsplash.com/random/800x600",
      videoSrc: "assets/images/clip-1.mp4",
    },
    {
      key: 1,
      title: "Make it your Own",
      description:
        "Quick presets to start, with deep customisation to match your website - fonts, colours, spacing, language.",
      // image: "https://source.unsplash.com/random/600x800",
      videoSrc: "assets/images/clip-3.mp4",
    },
    {
      key: 2,
      title: "Stay in Control",
      description:
        "Post your own questions (FAQ), order and reorder questions and customise questions per page",
      // image: "https://source.unsplash.com/random/800x1200",
      videoSrc: "assets/images/clip-2.mp4",
    },
  ];

  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="flex flex-col items-center">
      <div className="w-screen sm:w-full sm:flex sm:flex-col sm:items-center overflow-hidden">
        <div className="overflow-hidden overflow-x-scroll flex flex-row justify-between items-center w-full max-w-3xl mb-12 scrollbar-hide">
          {tabs.map((tab) => (
            <button
              key={tab.key}
              className={`${
                tab.key === activeTab
                  ? "text-zinc-800 border-b-4 border-zinc-600 font-bold"
                  : "text-zinc-600 border-b border-zinc-200 hover:text-zinc-800 hover:border-zinc-400 hover:font-bold"
              } w-full max-w-[14rem] sm:max-w-none sm:w-1/3 font-brandon text-base sm:text-lg py-3 antialiased flex justify-center shrink-0 px-6 sm:px-0`}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.title}
            </button>
          ))}
        </div>
      </div>

      <video
        src={tabs[activeTab].videoSrc}
        autoPlay
        loop
        muted
        className="shadow-xl"
      ></video>

      {/* <video aria-label="Hold engaging meetings where everyone has a chance to be heard." class="video-tabs__media" data-lazy-video="loaded" data-type="video" muted="true" playsinline="playsinline" poster="https://res.cloudinary.com/pitch-software/image/upload/f_auto/website-assets/home/use-cases-tabs/Editor_-_TeamMeeting_2.jpg"><source data-src="https://d3x4b1wy4qlu9.cloudfront.net/media/home/use-cases/Editor+-+TeamMeeting_2.webm" type="video/webm" src="https://d3x4b1wy4qlu9.cloudfront.net/media/home/use-cases/Editor+-+TeamMeeting_2.webm"><source data-src="https://d3x4b1wy4qlu9.cloudfront.net/media/home/use-cases/Editor+-+TeamMeeting_2.mp4" type="video/mp4" src="https://d3x4b1wy4qlu9.cloudfront.net/media/home/use-cases/Editor+-+TeamMeeting_2.mp4"> */}
      <p className="font-normal font-brandon text-base sm:text-xl mt-8 text-zinc-700 text-center antialiased px-6">
        {tabs[activeTab].description}
      </p>
    </div>
  );
}
export default FeatureTabs;
