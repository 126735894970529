import React, { useEffect, useState } from "react";
import axios from "axios";

export async function getTestimonials() {
  let resp;
  await axios
    .get("https://api.airtable.com/v0/appwBMPpVDCDEHMr6/hagrid", {
      headers: {
        Authorization: "Bearer key04kzdBL9zJQCwp",
      },
    })
    .then((response) => {
      resp = response;
    });

  return resp;
}

export function RenderTestimonial(props) {
  return (
    <li
      className="cursor-pointer hover:-translate-y-1.5"
      onClick={() => window.open(props.link, "_blank")}
    >
      <figure className="antialiased relative flex flex-col rounded-lg p-6 bg-white shadow-[8px_8px_0_0_rgba(0,0,0,0.8)] border border-black">
        <blockquote className="mb-6 text-sm sm:text-base font-brandon font-medium text-zinc-900">
          {props.quote}
        </blockquote>

        <figcaption className="flex items-start space-x-4">
          <img
            src={props.imgSrc}
            className="flex-none w-10 h-10 rounded-full object-cover"
            loading="lazy"
            alt=""
          />
          <div className="font-brandon">
            <p className="text-zinc-800 text-xs sm:text-sm font-bold">
              {props.name}
            </p>
            <p className="mt-1 text-[0.625rem] sm:text-xs font-semibold text-zinc-700">
              {props.designation}
            </p>
          </div>
        </figcaption>
      </figure>
    </li>
  );
}

export function Testimonials(props) {
  const [renderFirstCol, setRenderFirstCol] = useState();
  const [renderSecondCol, setRenderSecondCol] = useState();
  const [renderThirdCol, setRenderThirdCol] = useState();

  const testimonials = async () => {
    try {
      const res = await getTestimonials();
      if (res.status === 200) {
        const firstCol = res.data.records
          .filter((item) => item.fields["Column"] === "1")
          .map((testimonial) => {
            return (
              <RenderTestimonial
                key={testimonial.id}
                imgSrc={testimonial.fields["Attachments"][0].url}
                name={testimonial.fields["Name"]}
                designation={testimonial.fields["Designation"]}
                quote={testimonial.fields["Quote"]}
                link={testimonial.fields["Link"]}
              />
            );
          });
        setRenderFirstCol(firstCol);

        const secondCol = res.data.records
          .filter((item) => item.fields["Column"] === "2")
          .map((testimonial) => {
            return (
              <RenderTestimonial
                key={testimonial.id}
                imgSrc={testimonial.fields["Attachments"][0].url}
                name={testimonial.fields["Name"]}
                designation={testimonial.fields["Designation"]}
                quote={testimonial.fields["Quote"]}
                link={testimonial.fields["Link"]}
              />
            );
          });
        setRenderSecondCol(secondCol);

        const thirdCol = res.data.records
          .filter((item) => item.fields["Column"] === "3")
          .map((testimonial) => {
            return (
              <RenderTestimonial
                key={testimonial.id}
                imgSrc={testimonial.fields["Attachments"][0].url}
                name={testimonial.fields["Name"]}
                designation={testimonial.fields["Designation"]}
                quote={testimonial.fields["Quote"]}
                link={testimonial.fields["Link"]}
              />
            );
          });
        setRenderThirdCol(thirdCol);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    testimonials();
  }, []);

  return (
    <div className="grid grid-cols-1 gap-4 lg:gap-6 sm:grid-cols-2 lg:grid-cols-3">
      <ul className="space-y-4 lg:space-y-6">{renderFirstCol}</ul>
      <ul className="space-y-4 lg:space-y-6">{renderSecondCol}</ul>
      <ul className="space-y-4 lg:space-y-6 hidden lg:block">
        {renderThirdCol}
      </ul>
    </div>
  );
}
