import React from "react";

function H1(props) {
  return (
    <h1 className="text-zinc-900 text-5xl sm:text-7xl font-bevietnampro font-extrabold antialiased">
      {props.text}
    </h1>
  );
}
export { H1 };

function H2(props) {
  return (
    <h2 className="text-3xl sm:text-5xl font-bevietnampro font-extrabold text-zinc-900 antialiased">
      {props.text}
    </h2>
  );
}
export { H2 };

function H3(props) {
  return (
    <h3 className="font-brandon font-light text-xl sm:text-2xl text-zinc-800 antialiased">
      {props.text}
    </h3>
  );
}
export { H3 };
