import React from "react";
import { PrimaryButtonSmall } from "./buttons";

function Navbar(props) {
  const navigateToSection = (id) => {
    let offsetTop = document.getElementById(id).offsetTop;
    window.scrollTo({
      top: offsetTop - 100,
      behavior: "smooth",
    });
  };

  return (
    <nav className="bg-white h-20 flex justify-between items-center px-4 md:px-24 sticky top-0 w-full z-50 shadow-lg">
      <div className="flex items-center">
        <img
          src="/assets/images/hagrid-logo-alt.svg"
          alt="logo"
          loading="lazy"
          className="w-[7rem] hidden sm:block"
        />
        <img
          src="/assets/images/hagrid-logo.svg"
          alt="logo"
          loading="lazy"
          className="w-[2.75rem] block sm:hidden"
        />
      </div>
      <div className="flex items-center">
        <button
          className="hidden md:block hover:bg-black hover:bg-opacity-10 rounded-lg text-black py-3 px-8 font-bevietnampro font-bold text-base hover:-translate-y-1.5"
          onClick={() => navigateToSection("integrations")}
        >
          Integrations
        </button>
        <button
          className="hidden md:block hover:bg-black hover:bg-opacity-10 rounded-lg text-black py-3 px-8 font-bevietnampro font-bold text-base hover:-translate-y-1.5"
          onClick={() => navigateToSection("pricing")}
        >
          Pricing
        </button>
        <button
          className=" hover:bg-black hover:bg-opacity-10 rounded-lg text-black py-3 px-8 font-bevietnampro font-bold text-base hover:-translate-y-1.5"
          onClick={() =>
            window.open("https://dashboard.hagrid.io/login", "_self")
          }
        >
          Log In
        </button>
        {/* <button className="bg-black text-white py-3 px-8 rounded-lg font-bevietnampro font-bold text-base hover:-translate-y-1.5">
          Try for Free
        </button> */}
        <PrimaryButtonSmall
          text={"Try for Free"}
          onClick={() =>
            window.open("https://dashboard.hagrid.io/login", "_self")
          }
        />
      </div>
    </nav>
  );
}

export default Navbar;
