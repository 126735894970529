import React, { useState, useEffect } from "react";

const images = [
  {
    src: "assets/images/hero-img-1.svg",
    alt: "hero image",
    // classes: "shadow-[12px_12px_0_0_rgba(255,115,150,0.4)] rounded-2xl",
    classes: " shadow-2xl rounded-2xl scale-90",
  },
  {
    src: "assets/images/hero-img-2.svg",
    alt: "hero image",
    // classes: "shadow-[12px_12px_0_0_rgba(20,195,142,0.4)] rounded-2xl",
    classes: " shadow-2xl rounded-2xl scale-90",
  },
  {
    src: "assets/images/hero-img-3.svg",
    alt: "hero image",
    // classes: "shadow-[12px_12px_0_0_rgba(209,140,224,0.4)] rounded-2xl",
    classes: " shadow-2xl rounded-2xl scale-90",
  },
];

function ImageSwapper() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentIndex === images.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 3000);

    return () => clearInterval(intervalId);
  }, [currentIndex]);

  return (
    <div className="lg:hidden flex flex-col items-center">
      <div className={images[currentIndex].classes}>
        <img src={images[currentIndex].src} alt="" />
      </div>
      {/* <p className="font-brandon text-lg font-normal mt-12 text-zinc-700 text-center">
        hagrid can be used for any website, from{" "}
        <span
          className={`${
            currentIndex === 0
              ? "bg-[rgba(255,115,150,0.4)] rounded-full px-3 py-1.5 text-zinc-900 font-bold cursor-pointer"
              : ""
          }`}
        >
          e-commerce
        </span>{" "}
        to{" "}
        <span
          className={`${
            currentIndex === 1
              ? "bg-[rgba(20,195,142,0.4)] rounded-full px-3 py-1.5 text-zinc-900 font-bold cursor-pointer"
              : ""
          }`}
        >
          SAAS
        </span>{" "}
        pages to{" "}
        <span
          className={`${
            currentIndex === 2
              ? "bg-[rgba(209,140,224,0.4)] rounded-full px-3 py-1.5 text-zinc-900 font-bold cursor-pointer"
              : ""
          }`}
        >
          blogs
        </span>{" "}
        . Try it out.
      </p> */}
    </div>
  );
}
export default ImageSwapper;
